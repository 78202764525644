import {
  List,
  Datagrid,
  FunctionField,
  useCreate,
  useNotify,
  useUpdate,
  useDelete,
} from "react-admin"
//import { List, Datagrid, FunctionField, SearchInput, useRecordContext, Button } from "react-admin";
import { DatagridActionsColumn } from "../shared/components/DatagridActionsColumn"
import { RowButton } from "../shared/components/RowButton"
import { useListContext } from 'react-admin';
import { useState } from "react";
import AddUserDialog from "../forms/AddUserForm";
import { Button } from "@mui/material";
import { Roles } from "../constants";
import EditUserDialog from "../forms/EditUserForm";

const EmptyComponent = ({ onAddUser }: { onAddUser: any }) => (
  <div>
    <ListActions onAddUser={onAddUser} />
    <Datagrid>
      <FunctionField
        label="Email"
        render={() => ""}
      />
      <FunctionField
        label="Role"
        render={() => ""}
      />
      <DatagridActionsColumn label="Actions">
        <RowButton label="Edit" />
      </DatagridActionsColumn>
      <DatagridActionsColumn label="Activation">
        <RowButton
          label={"DELETE"}
          variant={"contained"}
          color={"error"}
          onClick={async (e) => {
            e.preventDefault()
          }}
        />
      </DatagridActionsColumn>
    </Datagrid>
  </div>
);

const ListActions = ({ onAddUser }: { onAddUser: any }) => (
  <>
    <Button variant="text" onClick={onAddUser} sx={{ margin: 2 }}>
      Add user
    </Button>
  </>
);

export const UserManagementList = (props: any) => {
  const { data } = useListContext();
  const [showPopup, setShowPopup] = useState(false);
  const [create] = useCreate();
  const [update] = useUpdate();
  const [deleteUser] = useDelete('user_role');
  const notify = useNotify();

  const [currentRecord, setCurrentRecord] = useState(null);

  const handleEdit = (record: any) => {
    setCurrentRecord(record);
  };

  const handleCloseEdit = () => {
    setCurrentRecord(null);
  };

  const handleUpdateUser = async (user: any) => {
    try {
      const data = {
        email: user.email,
        role_category_code: user.role,
      }
      await update("user_role", { id: user.id, data: data });
      notify(`User role updated successfully`, { type: "success" });
    } catch (err) {
      notify(`User role updated failed: ${(err as any).messsage}`, { type: "error" });
    }
  };

  const handleAddUser = async (user: any) => {
    try {
      const data = {
        email: user.email,
        role_category_code: user.role,
      }
      await create("user_role", { data });
      notify(`User role created successfully`, { type: "success" });
    } catch (err) {
      notify(`User role created failed: ${(err as any).messsage}`, { type: "error" });
    }
  };

  const handleDelete = async (user: any) => {
    try {
      await deleteUser("user_role", {id: user.id });
      notify(`User role deleted successfully`, { type: "success" });
    } catch (err) {
      notify(`User role deleted failed: ${(err as any).messsage}`, { type: "error" });
    }
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "email", order: "DESC" }}
        actions={<ListActions onAddUser={() => setShowPopup(true)} />}
        empty={<EmptyComponent onAddUser={() => setShowPopup(true)} />}
      >
        <Datagrid>
          <FunctionField
            label="Email"
            render={(record: any) =>
              `${record?.email || ""}`
            }
          />
          <FunctionField
            label="Role"
            render={(record: any) =>
              `${Roles.find((item) => item.value === record?.role_category_code)?.label  || ""}`
            }
          />
          <FunctionField
            label="Actions"
            render={(record: any) =>
              <RowButton label="Edit" onClick={() => handleEdit(record)} />
            }
          />
          <FunctionField
            label=""
            render={(record: any) =>
              <RowButton label="DELETE" variant="text" color={"error"} onClick={() => {
                if (window.confirm('Are you sure you want to delete this user?')) {
                  handleDelete(record)
                }
              }} />
            }
          />
        </Datagrid>
      </List>
      <AddUserDialog
        open={showPopup}
        onClose={() => setShowPopup(false)}
        onSave={handleAddUser}
      />
      {currentRecord && 
        <EditUserDialog
          open={!!currentRecord}
          onClose={handleCloseEdit}
          onSave={handleUpdateUser}
          id={(currentRecord as any)?.id}
          email={(currentRecord as any)?.email}
          role={(currentRecord as any)?.role_category_code}
        />
      }
    </>
  )
}
