import {
  List,
  Datagrid,
  FunctionField,
} from "react-admin"
import { useListContext } from 'react-admin';
import { actionToText, Roles } from "../constants";

const EmptyComponent = () => (
  <div>
    <Datagrid>
      <FunctionField
        label="Timestamp"
        render={() => ""}
      />
      <FunctionField
        label="Username"
        render={() => ""}
      />
      <FunctionField
        label="Role"
        render={() => ""}
      />
      <FunctionField
        label="Action"
        render={() => ""}
      />
      <FunctionField
        label="Target"
        render={() => ""}
      />
      <FunctionField
        label="Additional info"
        render={() => ""}
      />
    </Datagrid>
  </div>
);

export const AuditLogList = (props: any) => {
  const { data } = useListContext();
  return (
    <>
      <List
        {...props}
        sort={{ field: "created_at", order: "DESC" }}
        empty={<EmptyComponent />}
        exporter={false}
      >
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label="Timestamp"
            render={(record: any) =>
              `${record?.created_at || ""}`
            }
          />
          <FunctionField
            label="Username"
            render={(record: any) => 
              `${record?.user?.email || ""}`
            }
          />
          <FunctionField
            label="Role"
            render={(record: any) => 
              `${Roles.find((item) => item.value === record?.action_by_user_role)?.label || ""}`
            }
          />
          <FunctionField
            label="Action"
            render={(record: any) => 
              `${actionToText(record?.action_type || "")}`
            }
          />
          <FunctionField
            label="Target"
            render={(record: any) => 
              `${record?.target_type || ""}`
            }
          />
          <FunctionField
            label="Additional info"
            render={(record: any) => 
              `${record?.addtionalInfo || ""}`
            }
          />
        </Datagrid>
      </List>
    </>
  )
}
