import {
  List,
  Datagrid,
  FunctionField,
  useCreate,
  useNotify,
  useUpdate,
  useDelete,
} from "react-admin"
import { DatagridActionsColumn } from "../shared/components/DatagridActionsColumn"
import { RowButton } from "../shared/components/RowButton"
import { useListContext } from 'react-admin';
import { useState } from "react";
import { Button } from "@mui/material";
import AddDomainDialog from "../forms/AddDomainForm";
import EditDomainDialog from "../forms/EditDomainForm";

const EmptyComponent = ({ onAddDomain }: { onAddDomain: any }) => (
  <div>
    <ListActions onAddDomain={onAddDomain} />
    <Datagrid>
      <FunctionField
        label="Domain"
        render={() => ""}
      />
      <DatagridActionsColumn label="Actions">
        <RowButton label="Edit" />
      </DatagridActionsColumn>
      <DatagridActionsColumn label="">
        <RowButton
          label={"DELETE"}
          variant={"contained"}
          color={"error"}
          onClick={async (e) => {
            e.preventDefault()
          }}
        />
      </DatagridActionsColumn>
    </Datagrid>
  </div>
);

const ListActions = ({ onAddDomain }: { onAddDomain: any }) => (
  <>
    <Button variant="text" onClick={onAddDomain} sx={{ margin: 2 }}>
      Add domain
    </Button>
  </>
);

export const DomainManagementList = (props: any) => {
  const { data } = useListContext();
  const [showPopup, setShowPopup] = useState(false);
  const [create] = useCreate();
  const [update] = useUpdate();
  const [deleteDomain] = useDelete('restricted_email_domain');
  const notify = useNotify();

  const [currentRecord, setCurrentRecord] = useState(null);

  const handleEdit = (record: any) => {
    setCurrentRecord(record);
  };

  const handleCloseEdit = () => {
    setCurrentRecord(null);
  };

  const handleUpdateDomain = async (record: any) => {
    try {
      const data = {
        email_domain: record.domain,
      }
      await update("restricted_email_domain", { id: record.id, data: data });
      notify(`Domain updated successfully`, { type: "success" });
    } catch (err) {
      notify(`Domain updated failed: ${(err as any).messsage}`, { type: "error" });
    }
  };

  const handleAddDomain = async (record: any) => {
    try {
      const data = {
        email_domain: record.domain,
      }
      await create("restricted_email_domain", { data });
      notify(`Domain created successfully`, { type: "success" });
    } catch (err) {
      notify(`Domain created failed: ${(err as any).messsage}`, { type: "error" });
    }
  };

  const handleDelete = async (record: any) => {
    try {
      await deleteDomain("restricted_email_domain", {id: record.id });
      notify(`Domain deleted successfully`, { type: "success" });
    } catch (err) {
      notify(`Domain deleted failed: ${(err as any).messsage}`, { type: "error" });
    }
  };

  return (
    <>
      <List
        {...props}
        sort={{ field: "email_domain", order: "DESC" }}
        actions={<ListActions onAddDomain={() => setShowPopup(true)} />}
        empty={<EmptyComponent onAddDomain={() => setShowPopup(true)} />}
      >
        <Datagrid>
          <FunctionField
            label="Domain"
            render={(record: any) =>
              `${record?.email_domain || ""}`
            }
          />
          <FunctionField
            label="Actions"
            render={(record: any) =>
              <RowButton label="Edit" onClick={() => handleEdit(record)} />
            }
          />
          <FunctionField
            label=""
            render={(record: any) =>
              <RowButton label="DELETE" variant="text" color={"error"} onClick={() => {
                if (window.confirm('Are you sure you want to delete this domain?')) {
                  handleDelete(record)
                }
              }} />
            }
          />
        </Datagrid>
      </List>
      <AddDomainDialog
        open={showPopup}
        onClose={() => setShowPopup(false)}
        onSave={handleAddDomain}
      />
      {currentRecord && 
        <EditDomainDialog
          open={!!currentRecord}
          onClose={handleCloseEdit}
          onSave={handleUpdateDomain}
          id={(currentRecord as any)?.id}
          domain={(currentRecord as any)?.email_domain}
        />
      }
    </>
  )
}
