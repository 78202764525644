import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

type AddDomainDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
};

const AddDomainDialog: React.FC<AddDomainDialogProps> = ({ open, onClose, onSave }) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data: any) => {
    onSave(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ minWidth: '360px' }}>
      <DialogTitle>Add Domain</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="domain"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Domain"
                fullWidth
                margin="normal"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDomainDialog;
