export const PAYOUT_LIMIT_IN_USD = 100; // 100 USD

// Hasura
export enum ResourceType {
  kol_payout = "kol_payout",
  kol_profile = "kol_profile",
  client_profile = "client_profile",
  campaign = "campaign",
  admin = "admin",
  audit_log = "audit_log",
}

export const Roles = [
  { value: 'general-manager', label: 'General Manager' },
  { value: 'campaign-manager', label: 'Campaign Manager' },
  { value: 'system-admin', label: 'System Admin' },
];

export function actionToText(action: string) {
  if (action.startsWith("view")) {
    return "View";
  } else if (action.startsWith("edit")) {
    return "Edit";
  } else if (action.startsWith("delete")) {
    return "Delete";
  } else if (action.startsWith("create")) {
    return "Create";
  } else if (action.startsWith("search")) {
    return "Search";
  } else if (action.startsWith("import")) {
    return "Import";
  } else {
    return "";
  }
}
