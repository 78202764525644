import { gql } from "@apollo/client";

export const INSERT_KOL = gql`
    mutation InsertKol($kol: kol_profile_insert_input!) {
        insert_kol_profile(objects: [$kol]) {
            affected_rows
        }
    }
`;


export const INSERT_KOL_SOCIAL_PLATFORMS = gql`
    mutation InsertKolSocialPlatform($kolSocialPlatform: [kol_social_platform_insert_input!]!) {
        insert_kol_social_platform(objects: $kolSocialPlatform) {
            affected_rows
        }
    }
`;

export const UPDATE_KOL = gql`
    mutation UpdateKol($kol: kol_profile_set_input!, $id: uuid!) {
        update_kol_profile_by_pk(pk_columns: {id: $id}, _set: $kol) {
            id
        }
    }
`;

export const DELETE_KOL_SOCIAL_PLATFORM = gql`
    mutation DeleteKolSocialPlatform($kol_profile_id: uuid!) {
        delete_kol_social_platform(where: {kol_profile_id: {_eq: $kol_profile_id}}) {
          affected_rows
      } 
    }
`;

export const DELETE_KOL_PROFILE = gql`
    mutation DeleteKolProfile($kol_profile_id: uuid!) {
        delete_kol_profile(where: {id: {_eq: $kol_profile_id}}) {
          affected_rows
      } 
    }
`;

export const GET_LANGUAGES = gql`
    query GetLanguages {
        language {
            title
        }
    }
`;

export const GET_PRODUCT_CATEGORIES = gql`
    query GetCategories {
        product_category {
            name
        }
    }
`;

export const GET_CURRENCIES = gql`
    query GetCurrencies {
        currency_category {
            code
            name
            symbol
        }
    }
`;


export const GET_KOLS = gql`
    query MyQuery ($limit: Int, $page: Int, $username: String) {
      getListKOLs(input: {limit: $limit, page: $page, username: $username}) {
        totalPage
        totalRecord
        data {
          address
          brand
          co_no
          contact
          content_creativity
          created_at
          deliverables
          email
          event_attendance
          l8_multipost
          l8_video
          gender
          id
          ig_reels
          ig_photo
          ig_story
          ig_multipost
          tt_video
          tt_story
          kol_rate
          language
          livestream
          yt_video
          yt_shorts
          fb_photo
          fb_multipost
          fb_video
          name
          xhs_video
          xhs_multipost
          posting_punctuality
          updated_at
          status
          state
          secondary_category
          responsiveness
          remarks
          race
          content_usage
          photo_shoot
          video_shoot
          primary_category
          ig_story_highlights
          link_in_bio
          overall_rating
          currency
          kol_social_platforms {
            id
            kol_profile_id
            number_of_followers
            social_platform_code
            social_platform_handle
            social_platform_tier
          }
        }
      }
}
`;

export const GET_KOL_PLATFORMS = gql`
    query GetKolPlatforms {
        kol_social_platform {
            id
            kol_profile_id
            social_platform_code
            social_platform_handle
        }
    }
`;

export const GET_KOL_SOCIAL_PLATFORMS_BY_ID = gql`
  query GetKolSocialPlatforms($kol_profile_id: uuid!) {
    kol_social_platform(where: {kol_profile_id: {_eq: $kol_profile_id}}) {
      id
      social_platform_code
      social_platform_handle
    }
  }
`;

export const SEARCH_KOL_BY_NAME = gql`
    query SearchKOLByName($name: String!) {
        searchKOLByName(name: $name) {
            data {
                id
                name
                social_platform_code
                social_platform_handle
            }
        }
    }
`;  

export const GET_KOL_DETAILS = gql`
    query getKolDetail($id: uuid!, $name: String!) {
        getKOLDetail(id: $id, name: $name) {
            address
                brand
                co_no
                contact
                content_creativity
                created_at
                deliverables
                email
                event_attendance
                l8_multipost
                l8_video
                gender
                id
                ig_reels
                ig_photo
                ig_story
                ig_multipost
                tt_video
                tt_story
                kol_rate
                language
                livestream
                yt_video
                yt_shorts
                fb_photo
                fb_multipost
                fb_video
                name
                xhs_video
                xhs_multipost
                posting_punctuality
                updated_at
                status
                state
                secondary_category
                responsiveness
                remarks
                race
                content_usage
                photo_shoot
                video_shoot
                primary_category
                ig_story_highlights
                link_in_bio
                overall_rating
                currency
                kol_social_platforms {
                    id
                    kol_profile_id
                    number_of_followers
                    social_platform_code
                    social_platform_handle
                    social_platform_tier
                }
        }
    }

`;
