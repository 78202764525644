import { Stack, Button, Typography, Snackbar, IconButton, Alert } from '@mui/material';
import React, { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth0 } from '@auth0/auth0-react';
import { ENV } from '../configs/env';

const DataManagement = () => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { getIdTokenClaims } = useAuth0();

  const handleImport = () => {
    fileInput.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleUploadImport(file);
    }
    event.target.value = '';
  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage(undefined);
  };

  const handleUploadImport = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    let token;
    try {
      const idTokens = await getIdTokenClaims();
      if (idTokens && idTokens.__raw) {
        token = idTokens?.__raw;
      }
    } catch (error) {
      // Handle or throw error accordingly.
      console.error("Error getting access token", error);
    }
  
    const response = await fetch(`${ENV.REACT_APP_WEBHOOK_URL}/import-kols`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });
  
    if (!response.ok) {
      setErrorMessage('Network response was not ok');
      return;
    }
  
    const data = await response.json();
    setOpen(true);
    return data;
  };

  return (
    <div>
      <Stack spacing={"120px"} direction={"row"} padding={"24px"}>
        <Typography variant="h5">Import Data</Typography>
        <Button variant="contained" sx={{ height: '40px', borderRadius: '20px' }} onClick={handleImport}>IMPORT (csv)</Button>
      </Stack>
      <input
        type="file"
        ref={fileInput}
        style={{ display: 'none' }}
        accept=".csv"
        onChange={handleFileChange}
      />
      <Snackbar
        open={open || !!errorMessage}
        autoHideDuration={3000}
        onClose={() => handleClose() }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity={open ? "success" : "error"}
            variant="filled"
            sx={{ width: '100%' }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            { errorMessage !== undefined ? errorMessage : "Import successful" }
          </Alert>
      </Snackbar>
    </div>
  );
};

export default DataManagement;
