import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Roles } from '../constants';

type EditUserDialogProps = {
  id: string;
  email: string;
  role: string;
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
};

const EditUserDialog: React.FC<EditUserDialogProps> = ({ open, onClose, onSave, id, email, role }) => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data: any) => {
    onSave({
      ...data,
      id,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ minWidth: '360px' }}>
      <DialogTitle>Edit User</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="email"
            control={control}
            defaultValue={email}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                type="email"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            defaultValue={role}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Role"
                fullWidth
                margin="normal"
              >
                {Roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditUserDialog;
