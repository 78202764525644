import { useAuth0 } from "@auth0/auth0-react";

export const useRole = () => {
  const { user } = useAuth0();

  const userRole = user?.["https://hasura.io/jwt/claims"]?.[
    "x-hasura-default-role"
  ] as "system-admin" | "campaign-manager" | "general-manager" | null | undefined;

  //return "general-manager";
  return userRole;
};
