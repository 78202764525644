import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Roles } from '../constants';
import { useQuery } from '@apollo/client';
import GET_LIST_DOMAIN from '../queries/domain';

type AddUserDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
};

const AddUserDialog: React.FC<AddUserDialogProps> = ({ open, onClose, onSave }) => {
  const { handleSubmit, control } = useForm();
  const [emailError, setEmailError] = React.useState('');

  const { data: domains } = useQuery(GET_LIST_DOMAIN);

  const onSubmit = (data: any) => {
    if (domains?.restricted_email_domain?.some((domain: any) => data.email.endsWith(domain.email_domain))) {
      onSave(data);
      onClose();
      setEmailError('');
      return;
    }
    setEmailError('Email domain is not allowed');
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ minWidth: '360px' }}>
      <DialogTitle>Add User</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                error={!!emailError}
                helperText={emailError}
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Role"
                fullWidth
                margin="normal"
              >
                {Roles.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserDialog;
