import "./App.css";
import { Admin, Layout, Menu, Resource, AppBar } from "react-admin";
import buildHasuraProvider from "ra-data-hasura";
import { Fragment, useEffect, useState } from "react";
import { ApolloClient, DefaultOptions, InMemoryCache } from "@apollo/client";
import { customBuildFields } from "./hasura";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import { ENV } from "./configs/env";
import { KolProfilesList } from "./features/KolsList";
import { ApolloProviderComposer } from "./providers/apollo";
import { ToastContainer } from "react-toastify";
import { ResourceType } from "./constants";
import { useRole } from "./hooks/useRole";
import { GET_KOLS } from "./features/queries";
import { ReactComponent as IconKols } from './assets/ic_kols.svg';
import { ReactComponent as IconUsers } from './assets/ic-users.svg';
import { ReactComponent as IconDomain } from './assets/ic-domain.svg';
import { ReactComponent as IconData } from './assets/ic-data.svg';
import { ReactComponent as IconAuditLog } from './assets/ic-audit-log.svg';
import { ReactComponent as IconKolsInactive } from './assets/ic_kols-inactive.svg';
import { ReactComponent as IconUsersInactive } from './assets/ic-users-inactive.svg';
import { ReactComponent as IconDomainInactive } from './assets/ic-domain-inactive.svg';
import { ReactComponent as IconDataInactive } from './assets/ic-data-inactive.svg';
import { ReactComponent as IconAuditLogInactive } from './assets/ic-audit-log-inactive.svg';
import { UserManagementList } from "./features/UserManagementList";
import { DomainManagementList } from "./features/DomainManagementList";
import DataManagement from "./features/DataManagement";
import { AuditLogList } from "./features/AuditLogList";
import { useLocation } from 'react-router-dom';

enum SocialPlatformType {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  LEMON8 = 'LEMON8',
  XIAOHONGSHU = 'XIAOHONGSHU'
}

export const createApolloClient = (idToken: string) => {
  console.info(`[createApolloClient]`, { idToken });
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  return new ApolloClient({
    uri: ENV.REACT_APP_APOLLO_GRAPHQL_URL,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
    headers: {
      Authorization: `Bearer ${idToken}`
    },
  });
};

export const MyMenu = () => {
  const userRole = useRole();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <Menu>
      {(userRole === "campaign-manager" || userRole === "general-manager" || userRole === "system-admin") && (
        <Fragment>
          <Menu.DashboardItem to="/kol_profile" primaryText="Influencers" leftIcon={isActive("/kol_profile") ? <IconKols /> : <IconKolsInactive />} />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Menu.DashboardItem to="/user_role" primaryText="User Management" leftIcon={isActive("/user_role") ? <IconUsers /> : <IconUsersInactive />} />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Menu.DashboardItem to="/restricted_email_domain" primaryText="Domain Management" leftIcon={isActive("/restricted_email_domain") ? <IconDomain /> : <IconDomainInactive />} />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Menu.DashboardItem to="/audit_log" primaryText="Audit Log" leftIcon={isActive("/audit_log") ? <IconAuditLog /> : <IconAuditLogInactive />} />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Menu.DashboardItem to="/data_management" primaryText="Data Management" leftIcon={isActive("/data_management") ? <IconData /> : <IconDataInactive />} />
        </Fragment>
      )}
    </Menu>
  );
};

export function roleToText(role: any) {
  if (role === "accountant") {
    return "Accountant";
  } else if (role === "campaign-manager") {
    return "Campaign Manager";
  } else if (role === "general-manager") {
    return "General Manager";
  } else if (role === "system-admin") {
    return "System Admin";
  } else {
    return "";
  }
}


export const MyAppBar = () => {
  const { user, logout } = useAuth0();
  const role = useRole();
  return (
    <AppBar style={{ backgroundColor: '#6750A4', height: "50px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: 'center',
          width: "100%",
          height: "50px",
        }}
      >
        <span style={{ fontSize: 20 }}>KOLs.Asia</span>
        {user && (
          <div>
            <span style={{ marginRight: 10 }}>
              Hi, {user?.name} {roleToText(role) ? `(${roleToText(role)})` : ""}
            </span>
            <button
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Log out
            </button>
          </div>
        )}
      </div>
    </AppBar>
  );
};

export const MyLayout = (props: any) => (
  <Layout appBar={MyAppBar} {...props} menu={MyMenu} />
);

export const Entrance = () => {
  return (
    <Auth0Provider
      domain={ENV.REACT_APP_AUTH0_DOMAIN}
      clientId={ENV.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
    >
      <ApolloProviderComposer>
        <AuthProvider />
        <ToastContainer />
      </ApolloProviderComposer>
    </Auth0Provider>
  );
};

const App = ({ token }: { token: string }) => {
  const [dataProvider, setDataProvider] = useState(null);
  const userRole = useRole();
  const buildDataProvider = async () => {
    const client = createApolloClient(token);

    const dataProvider = await buildHasuraProvider(
      {
        client,
      },
      { buildFields: customBuildFields }
    );

    setDataProvider((introspection): any => {
      console.info(`[App]`, { introspection, dataProvider });
      return {
        ...dataProvider,
        getList: async (resource: any, params: any) => {
          if (resource === ResourceType.kol_profile) {
            const platformHandle = params.filter.platform_handle ?? "";
            let socialPlatform = params.filter.social_platform;
            
            if ((platformHandle === undefined || platformHandle === null || platformHandle === "") && userRole === 'campaign-manager') {
              return {
                data: [],
                total: 0
              };
            }

            let allKolsWithHandles = new Array();
            const QUERY_LIMIT = 9999;
            const allKols = await client.query({
              query: GET_KOLS,
              variables: {
                'limit': QUERY_LIMIT,
                'page': 1,
                'username': platformHandle
                
              }
            });
            const findExactly = userRole === "campaign-manager" ? true : false;
            for (let i = 0; i < allKols.data.getListKOLs.data.length; i++) {
              const kol = allKols.data.getListKOLs.data[i];
              if (findExactly) {
                for (let j = 0; j < kol.kol_social_platforms.length; j++) {
                  if (kol.kol_social_platforms[j].social_platform_handle === platformHandle) {
                    allKolsWithHandles.push(kol);
                    break;
                  }
                }
              }
              else {
                if (platformHandle == "") {
                  allKolsWithHandles.push(kol);
                  continue;
                }
                for (let j = 0; j < kol.kol_social_platforms.length; j++) {
                  if (kol.kol_social_platforms[j].social_platform_handle.includes(platformHandle)) {
                    allKolsWithHandles.push(kol);
                    break;
                  }
                }
              }

            }
            return {
              data: getSubArray(allKolsWithHandles, (params.pagination.page - 1) * params.pagination.perPage, params.pagination.perPage),
              total: allKolsWithHandles.length
            };
          } else {
            // Default behavior for other resources
            return dataProvider.getList(resource, params);
          }
        },
      };
    });
  };

  useEffect(() => {
    buildDataProvider();
  }, [token]);


  useEffect(() => {
    console.info(`[ENV]`, process.env);
  }, []);



  if (!dataProvider) {
    return <div>No data provider</div>;
  }

  if (!userRole) {
    return null;
  }

  return (
    <Admin
      dataProvider={dataProvider as any}
      layout={MyLayout}>
        
      {(userRole === "system-admin" || userRole === "general-manager" || userRole === "campaign-manager") && (
        <Fragment>
          <Resource
            name="kol_profile"
            list={KolProfilesList}
          />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Resource
            name="user_role"
            list={UserManagementList}
          />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Resource
            name="restricted_email_domain"
            list={DomainManagementList}
          />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Resource
            name="audit_log"
            list={AuditLogList}
          />
        </Fragment>
      )}
      {userRole === "system-admin" && (
        <Fragment>
          <Resource
            name="data_management"
            list={DataManagement}
          />
        </Fragment>
      )}
    </Admin>
  );
};

const AuthProvider: any = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  const [token, setToken] = useState<null | string>(null);

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((idToken) => {
        console.info(`[AuthProvider] got ID token ${JSON.stringify(idToken)}`);

        setToken(idToken?.__raw as string);
      });
    }
  }, [isAuthenticated, token]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) {
      (async () => {
        await loginWithRedirect();
      })();
    }
  }, [isLoading]);

  const onTryAgain = () => {
    logout({ returnTo: window.location.origin });
  };

  console.info(`[AuthProvider]`, { isAuthenticated, isLoading, error, token });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <div>
        <h1>Oops... {error.message}</h1>
        <button onClick={onTryAgain}>TRY AGAIN</button>
      </div>
    );
  }

  return (
    <div>{!token ? <div>Getting token...</div> : <App token={token} />}</div>
  );
};

function findSocialPlatformTypeByString(platformString: string): SocialPlatformType | undefined {
  const values = Object.values(SocialPlatformType);
  const found = values.find(value => value.toLowerCase() === platformString.toLowerCase());
  return found as SocialPlatformType | undefined;
}

function getSubArray<T>(array: T[], fromIndex: number, lengthOfSubArray: number): T[] {
  let endIndex = fromIndex + lengthOfSubArray;
  const subArray = array.slice(fromIndex, endIndex);
  return subArray;
}