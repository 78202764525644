import {
  useRefresh,
  List,
  Datagrid,
  FunctionField,
  Identifier,
} from "react-admin"
import Select from 'react-select';
import { ENABLE_DISABLE_KOL_PROFILE } from "../queries/kol-profile"
import React, { useEffect, useState, useRef } from "react"
import { useMutation, DefaultOptions } from "@apollo/client"
import { useRole } from "../hooks/useRole"
import { ReactComponent as SearchIcon } from "../assets/ic_search_kol.svg"
import { AddKolDialog } from "./AddKolDialog";
import { useApolloClient } from "@apollo/client";
import { INSERT_KOL, DELETE_KOL_SOCIAL_PLATFORM, UPDATE_KOL, DELETE_KOL_PROFILE, INSERT_KOL_SOCIAL_PLATFORMS, SEARCH_KOL_BY_NAME, GET_KOL_DETAILS } from "./queries";
import { v4 as uuid } from 'uuid';
import { useNotify } from "react-admin";
import '../App.css';
import { formatNumber } from "./AddKolDialog";


interface SocialPlatformSelectionDropdownProps {
  onChange: (value: string) => void;
}

const SocialPlatformSelection: React.FC<SocialPlatformSelectionDropdownProps> = ({ onChange }) => {
  const options = [
    { value: 'INSTAGRAM', label: 'Instagram' },
    { value: 'YOUTUBE', label: 'YouTube' },
    { value: 'TIKTOK', label: 'Tiktok' },
    { value: 'FACEBOOK', label: 'Facebook' },
    { value: 'XIAOHONGSHU', label: 'XHS' },
    { value: 'LEMON8', label: 'Lemon8' },
  ];

  const handleChange = (selectedOption: any) => {
    onChange(selectedOption.value);
  };

  return (
    <Select options={options} onChange={handleChange} styles={{
      container: (provided) => ({
        ...provided,
        width: 300, height: 40,
      }),
    }}
      placeholder="Select an option"
      defaultValue={options[0]}
    />
  );
};

interface SugguestionItem {
  id: any;
  name: string;
  platforms: any[]
}

interface SugguestionListProps {
  data: SugguestionItem[];
  onItemClick: (item: SugguestionItem) => void;
}

const platformNameInAbbrev = (platformCode: string) => {
  switch (platformCode) {
    case 'INSTAGRAM':
      return 'IG';
    case 'TIKTOK':
      return 'TT';
    case 'YOUTUBE':
      return 'YT';
    case 'FACEBOOK':
      return 'FB';
    case 'XIAOHONGSHU':
      return 'XHS';
    case 'LEMON8':
      return 'L8';
    default:
      return platformCode;
  }
}

function sugguestionInformation(sugguestion: SugguestionItem) {
  if (!sugguestion.platforms || sugguestion.platforms.length == 0 || sugguestion.platforms.every((platform) => !platform.social_platform_handle || `${platform.social_platform_handle}`.trim() === '')) {
    return `${sugguestion.name} (No Social Account)`;
  }

  let information = `${sugguestion.name} | `;
  for (let i = 0; i < sugguestion.platforms.length; i++) {
    information += `${sugguestion.platforms[i].social_platform_handle} (${platformNameInAbbrev(sugguestion.platforms[i].social_platform_code)})`;
    if (i < sugguestion.platforms.length - 1) {
      information += ' | ';
    }
  }
  return information
}

const SugguestionList: React.FC<SugguestionListProps> = ({ data, onItemClick }) => {
  return (
    <div style={{borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
      {data.map((item) => (
        <div key={item.id} style={{ 
          paddingLeft: '10px', paddingRight:'10px', paddingTop:'1px', paddingBottom:'1px', backgroundColor:'white', cursor:'pointer' }} onClick={()=>{
          onItemClick(item);
        }}>
          <p>{sugguestionInformation(item)}</p>
        </div>
      ))}
    </div>
  );
};

export default SugguestionList;



export const KolProfilesList = (props: any) => {


  const refresh = useRefresh()
  const client = useApolloClient()

  const [insertKol] = useMutation(INSERT_KOL)
  const [updateKol] = useMutation(UPDATE_KOL)
  const [deleteKol] = useMutation(DELETE_KOL_PROFILE)

  const [enableDisableKolProfile, enableDisableKolProfileResult] = useMutation<
    any,
    {
      isApproved: boolean
      isActive: boolean
      kolProfileId: string
    }
  >(ENABLE_DISABLE_KOL_PROFILE)

  if (
    enableDisableKolProfileResult.called &&
    !enableDisableKolProfileResult.loading
  ) {
    refresh()
  }
  
  const role = useRole()
  const [filter, setFilter] = useState({ social_platform: '', platform_handle: '' });

  const [showPopup, setShowPopup] = useState(false);

  const [selectedKol, setSelectedKol] = useState<any>(null);

  const notify = useNotify();

  const onRowClick = (record: any) => {
    setShowPopup(true);
    setSelectedKol(record);
    setSuggestions([]);
  }

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (showPopup) {
        setShowPopup(false);
        setSelectedKol({})
        event.preventDefault();
      }
      
    };

    window.addEventListener('popstate', handleBeforeUnload);
    const unlisten = () => {
      window.removeEventListener('popstate', handleBeforeUnload);
    };

    return () => {
      unlisten(); 
    };
  }, [showPopup]);

  


  const addKolButtonPosition = 10;
  const addKolButtonWidth = 100;
  const searchBarWidth = 300;
  const searchBarPosition = addKolButtonPosition + addKolButtonWidth + 10;
  const socialPlatformSelectionPosition = searchBarPosition + searchBarWidth + 10;
  const actionTopMargin = 40

  const suggestionListRef = useRef<HTMLDivElement>(null);
  const handleClickOutsideSugguestion = (event: MouseEvent) => {
    if (suggestionListRef.current && !suggestionListRef.current.contains(event.target as Node)) {
      setSuggestions([]);
    }
  };
  useEffect(() => {
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
    client.defaultOptions = defaultOptions
  }, []);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSugguestion);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSugguestion);
    };
  }, []);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [suggestions, setSuggestions] = useState<SugguestionItem[]>([]);

  const groupKolById = (searchKOLByNameResult: any) => {
    const sugguestion = searchKOLByNameResult.data.map((kol: any) => {
      return { id: kol.id, name: kol.name, social_platform_code: kol.social_platform_code, social_platform_handle: kol.social_platform_handle };
    })
    const displaySugguestion = Array<SugguestionItem>();
    const processedIds = Array<any>();
    for (let i = 0; i < sugguestion.length; i++) {
      let kol = sugguestion[i];
      if (processedIds.indexOf(kol.id) !== -1) {
        continue
      } else {
        let socialPlatforms = Array<any>();
        for (let j = 0; j < sugguestion.length; j++) {
          if (kol.id === sugguestion[j].id) {
            socialPlatforms.push({social_platform_code: sugguestion[j].social_platform_code, social_platform_handle: sugguestion[j].social_platform_handle}); 
          }
        }
        kol.platforms = socialPlatforms;
        displaySugguestion.push(kol);
        processedIds.push(kol.id);
      }
    }
    return displaySugguestion;
  }

  const fetchSugguestion = (value: string) => {
    client.query({
      query: SEARCH_KOL_BY_NAME,
      variables: {
        name: value
      },
      fetchPolicy: 'no-cache'
    }).then((result: any) => {
      setSuggestions(groupKolById(result.data.searchKOLByName));
    });
  }
  const getKolDetails = (sugguestion: any) => {
    client.query({
      query: GET_KOL_DETAILS,
      variables: {
        id: sugguestion.id,
        name: sugguestion.name
      }
    }).then((result: any) => {
      if (result.data.getKOLDetail) {
        onRowClick(result.data.getKOLDetail);
      }
      
    });
  }


  useEffect(() => {
    if (debouncedInputValue) {
      fetchSugguestion(debouncedInputValue);
      const newFilter = { social_platform: filter.social_platform, platform_handle: debouncedInputValue };
      setFilter(newFilter);
    } else {
      setSuggestions([]);
    }
  }, [debouncedInputValue]);

  return (
    <div style={{ position: 'relative', paddingTop: '10px' }}>
      {(role == "general-manager" || role == "campaign-manager" )&& (<p style={{ position: 'fixed', top: actionTopMargin, right: socialPlatformSelectionPosition, zIndex: 1000, marginTop: '20px', display:'none' }}>

        <SocialPlatformSelection onChange={(value) => {
          const newFilter = { social_platform: value, platform_handle: filter.platform_handle };
          setFilter(newFilter);
        }}></SocialPlatformSelection></p>)}
      <p style={{ position: 'fixed', top: actionTopMargin, right: searchBarPosition, zIndex: 1000, }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <input
            type="text"
            style={{
              height: '38px',
              width: '300px',
              backgroundColor: 'rgb(241, 241, 241)',
              borderRadius: '15px',
              border: '1px solid rgb(241, 241, 241)',
              padding: '10px',
              marginTop: '5px',
              paddingRight: '30px'  // Make room for the icon
            }}
            value={inputValue}
            placeholder="Input kol's platform handle"
            onChange={(event) => {
              if (event.target.value === '') {
                const newFilter = { social_platform: filter.social_platform, platform_handle: event.target.value };
                setFilter(newFilter);
              } 
              setInputValue(event.target.value);
            }}
          />
          <SearchIcon
            style={{
              position: 'absolute',
              height: '20px',
              width: '20px',
              top: '10px',
              marginTop: '5px',
              right: '10px'
            }}
          />
        </div>
        <div ref={suggestionListRef} style={{position:'fixed', maxWidth: '500px'}}>
        { fetchSugguestion.length > 0 && SugguestionList({ data: suggestions, onItemClick: (item: SugguestionItem) => {
          setInputValue(item.name);
          getKolDetails(item);
          setSuggestions([]);
        }}) }
        </div>
      </p>


      <button style={{
        position: 'fixed', top: actionTopMargin, height: '38px', backgroundColor: '#6750A4', borderRadius: '15px', width: addKolButtonWidth,
        paddingLeft: '20px', paddingRight: '20px', fontSize: '13px', color: 'white', justifyContent: 'center',
        border: '1px solid rgb(241, 241, 241)', marginTop: '20px', right: addKolButtonPosition, zIndex: 1000, alignItems: 'center'
      }} onClick={() => {
        onRowClick({});
      }}> Add KOL </button>

      {!showPopup && <List
        {...props}
        sort={{ field: "updated_at", order: "DESC" }}
        filter={filter}

        exporter={false}
        empty={ <div style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed'
        }}>
        </div> }
      >
        <div className="scrollable-container">
          <Datagrid rowClick={(id: Identifier, resource: string, record) => {
            onRowClick(record);
            return ""
          }}
            bulkActionButtons={false}
          >
            <FunctionField
              label="Full Name"
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {record?.name || ""}
                </div>
              )}
            />

            <FunctionField
              label="IG Handle"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'INSTAGRAM');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_handle : <span>---</span>}
                  </div>
                );
              }}

            />
            <FunctionField
              label="IG Followers"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'INSTAGRAM');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? <span>{formatNumber(instagramPlatform.number_of_followers)}</span> : <span>0</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="IG Tier"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'INSTAGRAM');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_tier : <span>---</span>}
                  </div>
                );
              }}

            />


            <FunctionField
              label="TikTok Handle"
              render={(record: any) => {
                const tiktokPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'TIKTOK');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {tiktokPlatform ? tiktokPlatform.social_platform_handle : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="TikTok Followers"
              render={(record: any) => {
                const tiktokPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'TIKTOK');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {tiktokPlatform ? <span>{formatNumber(tiktokPlatform.number_of_followers)}</span> : <span>0</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="TikTok Tier"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'TIKTOK');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_tier : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="YouTube Channel"
              render={(record: any) => {
                const youtubePlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'YOUTUBE');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {youtubePlatform ? youtubePlatform.social_platform_handle : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="YouTube Followers"
              render={(record: any) => {
                const youtubePlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'YOUTUBE');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {youtubePlatform ? <span>{formatNumber(youtubePlatform.number_of_followers)}</span> : <span>0</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="YouTube Tier"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'YOUTUBE');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_tier : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="XHS Handle"
              render={(record: any) => {
                const xhsPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'XIAOHONGSHU');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {xhsPlatform ? xhsPlatform.social_platform_handle : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="XHS Followers"
              render={(record: any) => {
                const xhsPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'XIAOHONGSHU');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {xhsPlatform ? <span>{formatNumber(xhsPlatform.number_of_followers)}</span> : <span>0</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="XHS Tier"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'XIAOHONGSHU');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_tier : <span>---</span>}
                  </div>
                );
              }}

            />


            <FunctionField
              label="Facebook Handle"
              render={(record: any) => {
                const facebookPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'FACEBOOK');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {facebookPlatform ? facebookPlatform.social_platform_handle : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="Facebook Followers"
              render={(record: any) => {
                const facebookPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'FACEBOOK');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {facebookPlatform ? <span>{formatNumber(facebookPlatform.number_of_followers)}</span> : <span>0</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="Facebook Tier"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'FACEBOOK');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_tier : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="Lemon8 Handle"
              render={(record: any) => {
                const lemon8Platform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'LEMON8');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {lemon8Platform ? lemon8Platform.social_platform_handle : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="Lemon8 Followers"
              render={(record: any) => {
                const lemon8Platform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'LEMON8');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {lemon8Platform ? <span>{formatNumber(lemon8Platform.number_of_followers)}</span> : <span>0</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label="Lemon8 Tier"
              render={(record: any) => {
                const instagramPlatform = record.kol_social_platforms.find((platform: any) => platform.social_platform_code === 'LEMON8');
                return (
                  <div style={{ whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px', left: 0 }} onClick={() => {
                    onRowClick(record);
                  }}>
                    {instagramPlatform ? instagramPlatform.social_platform_tier : <span>---</span>}
                  </div>
                );
              }}

            />

            <FunctionField
              label={<span>Contact</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.contact}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Email</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.email}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Address</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.address}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>State</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.state}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Race</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.race}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Gender</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.gender}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Language</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.language}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Primary category</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.primary_category}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Secondary category</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.secondary_category}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Currency</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{getKolCurrency(record)}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Co No</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.co_no}</span>}
                </div>
              )}

            />

            <FunctionField
              label={<span>Applied Brand</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.brand}</span>}
                </div>
              )}

            />
            <FunctionField
              label="Deliverables"
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '200px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.deliverables}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>KOL Rate - RM</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.kol_rate)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>IG Photo</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.ig_photo)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>IG Multipost</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.ig_multipost)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>IG Reels</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.ig_reels)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>IG Story</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.ig_story)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>TT Video</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.tt_video)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>TT Story</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.tt_story)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>XHS Multipost</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.xhs_multipost)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>XHS Video</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.xhs_video)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>L8 Video</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.l8_video)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>L8 Multipost</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.l8_multipost)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>YouTube Video</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.yt_video)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>YouTube Shorts</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.yt_shorts)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>FB Photo</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.fb_photo)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>FB Multipost</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.fb_multipost)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>FB Video</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.fb_video)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Livestream</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.livestream)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Photo Shoot</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.photo_shoot)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Video Shoot</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.video_shoot)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Event Attendance</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.event_attendance)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Content Usage</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.content_usage)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>IG Story Highlights </span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.ig_story_highlights)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Link in bio</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.link_in_bio}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Content creativity</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.content_creativity)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Posting Punctuality</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.posting_punctuality)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Responsiveness</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.responsiveness)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Overall Rating</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{formatNumber(record?.overall_rating)}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Status</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.status}</span>}
                </div>
              )}

            />
            <FunctionField
              label={<span>Remarks</span>}
              render={(record: any) => (
                <div style={{
                  whiteSpace: 'nowrap', position: 'sticky', minWidth: '100px',
                  left: 0,
                }} onClick={() => {
                  onRowClick(record);
                }}>
                  {<span>{record?.remarks}</span>}
                </div>
              )}

            />

          </Datagrid>
        </div>

      </List>}
      <AddKolDialog open={showPopup} kolData={selectedKol} onClose={() => {
        setShowPopup(false);
        setSelectedKol({});

      }} onSave={(data) => {
        setShowPopup(false);
        setSelectedKol({});
        const kol_profile_id = data.id;


        const id = kol_profile_id ?? uuid();
        const newKolData = {
          'id': id,
          'name': data.name,
          'contact': data.contact,
          'email': data.email,
          'address': data.address,
          'state': data.state,
          'race': data.race,
          'gender': data.gender,
          'language': data.language,
          'livestream': data.livestream,
          'content_usage': data.content_usage,
          'primary_category': data.primary_category,
          'secondary_category': data.secondary_category,
          'co_no': data.co_no,
          'brand': data.brand,
          'deliverables': data.deliverables,
          'kol_rate': data.kol_rate,
          'status': data.status,
          'content_creativity': data.content_creativity,
          'posting_punctuality': data.posting_punctuality,
          'responsiveness': data.responsiveness,
          'remarks': data.remarks,
          'ig_photo': data.ig_photo,
          'ig_story': data.ig_story,
          'ig_reels': data.ig_reels,
          'ig_multipost': data.ig_multipost,
          'tt_video': data.tt_video,
          'tt_story': data.tt_story,
          'xhs_multipost': data.xhs_multipost,
          'xhs_video': data.xhs_video,
          'live_stream': data.live_stream,
          'photo_shoot': data.photo_shoot,
          'video_shoot': data.video_shoot,
          'event_attendance': data.event_attendance,
          'ig_story_highlights': data.ig_story_highlights,
          'link_in_bio': data.link_in_bio,
          'l8_video': data.l8_video,
          'l8_multipost': data.l8_multipost,
          'yt_video': data.yt_video,
          'yt_shorts': data.yt_shorts,
          'fb_photo': data.fb_photo,
          'fb_video': data.fb_video,
          'fb_multipost': data.fb_multipost,
          'overall_rating': data.overall_rating,
          'currency': data.currency,
        }

        const performKolOperation = new Promise((resolve, reject) => {
          if (kol_profile_id) {
            updateKol({
              variables: {
                kol: newKolData,
                id: id
              }
            }).then((res) => {

              client.mutate({
                mutation: DELETE_KOL_SOCIAL_PLATFORM,
                variables: {
                  kol_profile_id: id
                }
              }).

                then((res) => {
                  if (data.kol_social_platforms) {
                    const platforms = data.kol_social_platforms.map((platform: any) => {
                      return {
                        'kol_profile_id': id,
                        'social_platform_code': platform.social_platform_code,
                        'social_platform_handle': platform.social_platform_handle,
                        'number_of_followers': platform.number_of_followers,
                        'social_platform_tier': platform.social_platform_tier,
                      }
                    })

                    client.mutate({
                      mutation: INSERT_KOL_SOCIAL_PLATFORMS,
                      variables: {
                        kolSocialPlatform: platforms
                      }
                    }).then((res) => {
                      refresh()
                      notify(`KOL is updated successfully`, { type: "success" });
  
                    }).catch((err) => {
                      console.error("err inserting: ", err)
                      notify(`An error occured, please try again later `, { type: "error" });
                    })
                    
                  }
                  
                }).catch((err) => {
                  console.error("err deleting: ", err)
                  notify(`An error occured, please try again later `, { type: "error" });
                })
            }).catch((err) => {
              console.error("err saving: ", err)
              notify(`An error occured, please try again later `, { type: "error" });
            });
          } else {
            insertKol({
              variables: {
                kol: newKolData
              }
            }
            ).then((res) => {
              if (role === 'general-manager') {
                client.mutate({
                  mutation: DELETE_KOL_SOCIAL_PLATFORM,
                  variables: {
                    kol_profile_id: id
                  }
                }).then((res) => {
                    if (data.kol_social_platforms) {
                      const platforms = data.kol_social_platforms.map((platform: any) => {
                        return {
                          'kol_profile_id': id,
                          'social_platform_code': platform.social_platform_code,
                          'social_platform_handle': platform.social_platform_handle,
                          'number_of_followers': platform.number_of_followers,
                          'social_platform_tier': platform.social_platform_tier,
                        }
                      })

                      client.mutate({
                        mutation: INSERT_KOL_SOCIAL_PLATFORMS,
                        variables: {
                          kolSocialPlatform: platforms
                        }
                      }).then((res) => {
                        refresh()
                        notify(`KOL is added successfully`, { type: "success" });
    
                      }).catch((err) => {
                        console.error("err inserting: ", err)
                        notify(`An error occured, please try again later `, { type: "error" });
                      })

                      
                    } else {
                      notify(`KOL is added successfully`, { type: "success" });
                    }
                  }).catch((err) => {
                    console.error("err deleting: ", err)
                    notify(`An error occured, please try again later `, { type: "error" });
                  })
              }
              else {
                if (data.kol_social_platforms) {
                  const platforms = data.kol_social_platforms.map((platform: any) => {
                    return {
                      'kol_profile_id': id,
                      'social_platform_code': platform.social_platform_code,
                      'social_platform_handle': platform.social_platform_handle,
                      'number_of_followers': platform.number_of_followers,
                      'social_platform_tier': platform.social_platform_tier,
                    }
                  })

                  client.mutate({
                    mutation: INSERT_KOL_SOCIAL_PLATFORMS,
                    variables: {
                      kolSocialPlatform: platforms
                    }
                  }).then((res) => {
                    refresh()
                    notify(`KOL is added successfully`, { type: "success" });

                  }).catch((err) => {
                    console.error("err inserting: ", err)
                    notify(`An error occured, please try again later `, { type: "error" });
                  })
                  
                } else {
                  notify(`KOL is added successfully`, { type: "success" });
                }
                
              }
              
            }).catch((err) => {
              console.error("err saving: ", err)
              notify(`An error occured, please try again later `, { type: "error" });
            })
          }
        })

      }}
        onDelete={(data) => {
          const kol_profile_id = selectedKol.id;
          setShowPopup(false);
          setSelectedKol({});
          client.mutate({
            mutation: DELETE_KOL_SOCIAL_PLATFORM,
            variables: {
              kol_profile_id: kol_profile_id
            }
          }).then((res) => {
            deleteKol({
              variables: {
                kol_profile_id: kol_profile_id
              }
            }).then((res) => {
              refresh();
              notify(`KOL is deleted successfully`, { type: "success" });
            }).catch((err) => {
              console.error('deleteKol_err', err);
              notify(`An error occured, please try again later `, { type: "error" });
            });
          }).catch((err) => {
            console.error('deleteKol_err', err);
            notify(`An error occured, please try again later `, { type: "error" });
          })

        }} />

    </div>
  )
}

function getKolCurrency(kol: any) {
  if (kol.currency === '' || !kol.currency) {
    return kol.currency;
  }
  const paths = kol.currency.split(' - ');
  if (paths.length > 1) {
    return paths[1];
  }
}

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}






